import { AllHandoutSection } from '@/components/Landing/AllHandoutSection'
import { MainBannerSection } from '@/components/Landing/MainBanner'
import { MainPageSkeleton } from '@/components/Landing/MainPageSkeletons'
import { ManualCurationSection } from '@/components/Landing/ManualCurationSection'
import { MiniBannerSection } from '@/components/Landing/MiniBannerSection'
import { RankingSection } from '@/components/Landing/RankingSection'
import { SelectedBooksDropDown } from '@/components/Landing/SelectedBookDropDown'
import { SelectedBookDropDownMobile } from '@/components/Landing/SelectedBookDropDownMobile'
import { SelectedBooksSection } from '@/components/Landing/SelectedBookSection'
import { SubjectSection } from '@/components/Landing/SubjectSection'
import { UserSourceStepModal } from '@/components/Landing/modals/UserSourceStepModal'
import CheckUniversityDialog from '@/components/UniversityShop/CheckUniversityDialog'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { SIGNUP_AFTER_USER_PROFILE } from '@/settings/constant'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { BannerItemDTO, RankingItemDTO, getMain, itemDTO } from 'src/api/main'
import { UserType, getUserMeta } from 'src/api/users'
import { useMainPageStore, useSavedUserSourceStore } from 'src/store/main'
import { useModal } from 'src/utils/overlay'
import styled from 'styled-components'
import SwiperCore, {
	A11y,
	Autoplay,
	EffectCoverflow,
	Grid,
	Navigation,
	Pagination,
	Scrollbar,
} from 'swiper'
import { Categories, Subjects } from '../../../api/product'

export const LandingPage: React.FC = () => {
	SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay, A11y, Grid, EffectCoverflow])
	const { user, isLoggedIn, updateUser } = useAuthStore()
	const [bottom, inView] = useInView()
	const { width } = useWindowDimensions()
	const { push } = useRouter()
	const isMobile = width < 768
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const getMeMutation = useMutation(getUserMeta)
	const [isSelectBooksModalOpen, { open: openSelectBooksModal, close: closeSelectBooksModal }] =
		useModal('selectBooksModal')
	const { savedUserSource, savedUserMeta, updateUserMeta } = useSavedUserSourceStore(
		({ savedUserSource, savedUserMeta, updateUserMeta }) => ({
			savedUserSource,
			savedUserMeta,
			updateUserMeta,
		}),
	)
	const {
		subject,
		setSubject,
		selectedBookList,
		setAllSelectedBookList,
		selectedBook,
		setSelectedBook,
		modalType,
		setModalType,
		selectedDataType,
		setSelectedDataType,
	} = useMainPageStore(
		({
			subject,
			setSubject,
			selectedBookList,
			setAllSelectedBookList,
			selectedBook,
			setSelectedBook,
			modalType,
			setModalType,
			selectedDataType,
			setSelectedDataType,
		}) => ({
			subject,
			setSubject,
			selectedBookList,
			setAllSelectedBookList,
			selectedBook,
			setSelectedBook,
			modalType,
			setModalType,
			selectedDataType,
			setSelectedDataType,
		}),
	)

	const [guidTooltipOpen, setGuidTooltipOpen] = useState(true)

	const handleSubject = (newSubject: Subjects) => {
		if (newSubject === subject) return
		setSubject(newSubject)
		getSavedUserSource(newSubject)

		mixpanelEvent('Subject Home Page Viewed', {
			subject,
			isLoggedIn,
			userId: user?.id,
			userType: savedUserMeta?.job,
		})
	}
	const handleDataType = (type: Categories) => {
		setSelectedDataType(type)
		mixpanelEvent('Ranking Tab Clicked', {
			subject,
			contentsType: type,
			isLoggedIn,
			userType: savedUserMeta?.job,
		})
	}
	const handleSelectedBook = (book: itemDTO) => {
		setSelectedBook(book)
		mixpanelEvent('Selected Book Clicked', {
			subject,
			selectedBookId: book.id,
			selectedBookTitle: book.title,
		})
	}
	const onClickBanner = (banner: BannerItemDTO, bannerType: string) => {
		mixpanelEvent('Subject Home Banner Clicked', {
			subject,
			bannerType,
			isLoggedIn,
			userType: savedUserMeta?.job,
			bannerUrl: banner.link,
		})
	}
	const onClickCuration = (book: RankingItemDTO, title: string) => {
		mixpanelEvent('Curation Product Clicked', {
			subject,
			productId: book.id,
			productTitle: book.title,
			productType: 'handout',
			isLoggedIn,
			userType: savedUserMeta?.job,
			curationTitle: title,
			curationType: selectedDataType,
			price: book.price,
			authorName: book.author,
			selectedBookId: selectedBook?.id,
			selectedBookTitle: selectedBook?.title,
			'Coupon Badge': book?.isCouponAvailable,
			'Edit Badge': book?.isEditable,
			'Review Badge': book?.rating >= 3,
		})
	}
	const getUserSourcePanel = (data) => {
		if (isLoggedIn && selectedBookList.length === 0) {
			setAllSelectedBookList(data?.userSourcePanel?.items || [])
		}
		if (!subject || subject !== data.mainSubject) {
			setSubject(data.mainSubject)
		}

		if (data.userSourceData.isEmpty || !data.userSourceData.salesRanking) {
			return
		}
		if (!selectedDataType) {
			setSelectedDataType(data.userSourceData.salesRanking.type.selected)
		}
	}
	const getSavedUserSource = (subject?) => {
		if (isLoggedIn) {
			return
		}
		if (savedUserSource.length === 0) {
			return
		}
		let searchSubject = subject
		if (!subject) {
			if (savedUserSource.length === 2) {
				searchSubject = Subjects.EN
				setSubject(Subjects.EN)
			} else if (savedUserSource.length === 1) {
				setSubject(savedUserSource[0].subject)
				searchSubject = savedUserSource[0].subject
			}
		}
		const foundSource = savedUserSource.find((item) => item.subject === searchSubject)
		if (foundSource) {
			setAllSelectedBookList(foundSource.selectedBookList)
		}
	}
	const { data, isLoading, isFetching, isError } = useQuery(
		['main', selectedBook, selectedDataType, subject, user?.id],
		() =>
			getMain({
				subject: subject,
				userSource: selectedBook?.id.toString(),
				salesRankingType: selectedDataType,
			}),
		{
			retry: 1,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			enabled: !isSelectBooksModalOpen,
			onSuccess: (data) => {
				getUserSourcePanel(data)
			},
		},
	)
	const fetchUserMeta = async () => {
		const res = await getMeMutation.mutateAsync()
		//등록된 정보가 없을경우, 받기
		if (!res?.job) {
			await push({
				pathname: SIGNUP_AFTER_USER_PROFILE,
				query: { alreadySignupUser: true },
			})
			return
		}
		//업데이트가 안됐을 경우 업데이트
		updateUserMeta({
			mainSubject: res.mainSubject as Subjects,
			job: res.job as UserType,
		})

		return
	}
	useEffect(() => {
		setSubject(null)
		getSavedUserSource()
		if (isLoggedIn && !savedUserMeta?.job) {
			fetchUserMeta()
		}
	}, [isLoggedIn, savedUserMeta?.job])
	if (isLoading || isError)
		return (
			<>
				<SubjectSection setSubject={handleSubject} subject={subject} />
				<MainPageSkeleton />
			</>
		)
	return (
		<LandingPageWrapper>
			{/*과목탭*/}
			<SubjectSection setSubject={handleSubject} subject={subject} />
			{/*맞춤교재 배너*/}
			<SelectedBooksSection
				openSelectBooksModal={openSelectBooksModal}
				selectBookList={selectedBookList}
				selectedBook={selectedBook}
				setSelectedBook={handleSelectedBook}
				bottom={bottom}
				setModalType={setModalType}
				subject={subject}
			/>
			{/*맞춤교재 변경 드롭다운*/}
			{!inView && (
				<SelectedBooksDropDown
					openSelectBooksModal={openSelectBooksModal}
					selectBookList={selectedBookList}
					selectedBook={selectedBook}
					setSelectedBook={handleSelectedBook}
					isOpenDropdown={isDropdownOpen}
					setIsOpenDropdown={setIsDropdownOpen}
					guidTooltipOpen={guidTooltipOpen}
					setGuidTooltipOpen={setGuidTooltipOpen}
					setModalType={setModalType}
				/>
			)}
			{/*메인 배너*/}
			<MainBannerSection data={data?.mainBanner} onClick={onClickBanner} subject={subject} />

			{/*유형별 실시간 추천*/}
			<RankingSection
				isEmptyRanking={data?.userSourceData.isEmpty}
				data={data?.userSourceData.salesRanking}
				handleDataType={handleDataType}
				selectedDataType={selectedDataType}
				selectedBook={selectedBook}
				showSkeleton={isFetching}
				onClick={onClickCuration}
			/>
			{/*미니배너*/}
			<MiniBannerSection data={data?.userSourceData.miniBanner} onClick={onClickBanner} />

			{/*수동 큐레이션 섹션*/}
			{data?.userSourceData.curation?.sections.length > 0 &&
				data?.userSourceData.curation?.sections.map((section, index) => {
					return (
						<ManualCurationSection
							key={section.title + index}
							showSkeleton={isFetching}
							data={section}
							onClick={onClickCuration}
						/>
					)
				})}
			{/*최근 수업자료 섹션*/}
			<AllHandoutSection
				isEmptyRanking={data?.userSourceData.isEmpty}
				data={data?.userSourceData.allProduct}
				handleDataType={setSelectedDataType}
				selectedDataType={selectedDataType}
				selectedBook={selectedBook}
				showSkeleton={isFetching}
				subject={subject}
			/>
			{/*//전체자료 섹션*/}
			{data?.userSourceData.curationAll?.sections.length > 0 &&
				data?.userSourceData.curationAll?.sections.map((section, index) => {
					return (
						<ManualCurationSection
							key={section.title + index}
							showSkeleton={isFetching}
							data={section}
							onClick={onClickCuration}
						/>
					)
				})}
			{/*맞춤교재 등록모달*/}
			<UserSourceStepModal
				closeSelectBooksModal={closeSelectBooksModal}
				isSelectBooksModalOpen={isSelectBooksModalOpen}
				modalType={modalType}
			/>
			{/*맞춤교재 변경 드롭다운 모바일 */}
			{isMobile && (
				<SelectedBookDropDownMobile
					isDropdownOpen={isDropdownOpen}
					openSelectBooksModal={openSelectBooksModal}
					selectBookList={selectedBookList}
					selectedBook={selectedBook}
					setSelectedBook={setSelectedBook}
					setIsDropdownOpen={setIsDropdownOpen}
				/>
			)}
			{/* 대학생의 경우, 메인에서 대학생 확인용 다이얼로그를 띄웁니다 */}
			{user.job === ('university' as UserType) && <CheckUniversityDialog />}
			{/*<PassInfoModal />*/}
		</LandingPageWrapper>
	)
}

const LandingPageWrapper = styled.div`
	p,
	span {
		line-height: 1.5;
	}
`
