import { ActiveTab } from '@/components/common/ActiveTab'
import { Tabs } from 'antd'
import React from 'react'
import { Subjects } from 'src/api/product'
import styled from 'styled-components'
import { PromotionLink } from './PromotionLink'

export interface SubjectSectionProps {
	subject: Subjects
	setSubject: (subject: Subjects) => void
}

export const SubjectSection: React.FC<SubjectSectionProps> = ({ subject, setSubject }) => {
	const subjectTabList = [
		{
			key: Subjects.EN,
			label: '영어',
		},
		{
			key: Subjects.KO,
			label: '국어',
		},
		{
			key: Subjects.MA,
			label: '수학',
		},
	]

	return (
		<SubjectSectionWrapper>
			<div>
				<TabsNav items={subjectTabList} onChange={setSubject} activeKey={subject} />
				<PromotionLink />
			</div>
		</SubjectSectionWrapper>
	)
}
export const TabsNav = styled(Tabs)`
	.ant-tabs-nav {
		margin-bottom: 0;
		::before {
			display: none;
		}
	}
	.ant-tabs-nav-wrap {
		.ant-tabs-ink-bar {
			background-color: ${(props) => props.theme.new.colors.gray900};
		}
		.ant-tabs-nav-list {
			.ant-tabs-tab {
				padding: 0;
				.ant-tabs-tab-btn {
					padding: 0 0 10px 0;
					font-size: 20px;
					line-height: 1.5;
					font-weight: 600;
					color: ${(props) => props.theme.new.colors.gray500};
				}
			}
			.ant-tabs-tab-active {
				.ant-tabs-tab-btn {
					color: ${(props) => props.theme.new.colors.gray900};
				}
			}
			.ant-tabs-tab + .ant-tabs-tab {
				margin-left: 20px;
			}
		}
	}
	@media (max-width: 768px) {
		.ant-tabs-nav-wrap {
			.ant-tabs-nav-list {
				.ant-tabs-tab {
					.ant-tabs-tab-btn {
						padding: 0 0 8px 0;
						font-size: 16px;
					}
				}
				.ant-tabs-tab + .ant-tabs-tab {
					margin-left: 12px;
				}
			}
		}
	}
`
const SubjectSectionWrapper = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	width: 100vw;
	margin-left: calc(50% - 50vw);
	padding-top: 12px;
	background-color: #fff;

	> div {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		max-width: 1200px;
		//margin: 0 auto;
	}
  

	@media (max-width: 1200px) {
		padding: 12px 32px 0;
		width: calc(100% + 80px);
		margin: 0 -40px;
	}
	@media (max-width: 768px) {
		width: calc(100% + 32px);
		margin: 0 -16px;
		padding: 0 16px;
		a {
			display: none;
		
	}
`
